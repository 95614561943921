<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title>
                <span>Subir archivo de carga masiva</span>
                <v-spacer></v-spacer>
                <v-btn href="/storage/carga_masiva_de_productos_pim.xlsx" target="_blank" class="ma-2" tile outlined color="success">
                <v-icon left>mdi-download-outline</v-icon> Descargar archivo formato excel
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-alert
                border="top"
                colored-border
                type="info"
                elevation="2"
                >
                    <v-alert
                    class="mt-8"
                    color="white"
                    border="right"
                    >
                        Cada fila es un <b>producto unico</b> o un <b>producto de un bundle</b>. En el caso de los productos de un bundle, deben tener el mismo valor en la columna sku para poder identificarlos.
                    </v-alert>
                    <v-alert
                    class="mt-2"
                    color="white"
                    border="right"
                    >
                        Los valores en las columnas con fondo de color <v-chip color="yellow">AMARILLO</v-chip> son opcionales.
                    </v-alert>
                    <v-alert
                    class="mt-2"
                    color="white"
                    border="right"
                    >
                        Los valores en las columnas con fondo de color <v-chip color="red">ROJO</v-chip> son obligatorias.
                    </v-alert>
                    <v-alert
                    class="mt-2"
                    color="white"
                    border="right"
                    >
                        Los valores en las columnas con fondo de color <v-chip color="blue lighten-3">CELESTE</v-chip> son obligatorias solo en caso de ser una fila de tipo "producto de un bundle".
                    </v-alert>
                </v-alert>
                <v-file-input
                v-model="file"
                color="deep-purple accent-4"
                placeholder="Sube un archivo formato .xslx"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
                :disabled="loading"
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                        >
                        {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="blue darken-1" @click="showDialog = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn dark color="success" :disabled="!readyForUpload || loading" @click="sendForm" >Subir</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            file: undefined,
            loading: false,
        }
    },
    created(){
        this.$parent.$on('open-carga-masiva-productos-dialog', () => {
            this.showDialog = true;
        });
    },
    methods:{
        sendForm(){
            this.loading = true;
            this.$nextTick(() => {
                let formData = new FormData();
                formData.append("file", this.file, this.file.name);
                var vm = this;
                this.axios({
                    url: 'order/products_pim/bulk_upload',
                    method: 'POST',
                    data: formData,
                }).then( () => {
                    /*
                    vm.$bvToast.toast(
                        'Archivo subido con exito',
                        {
                            title: `Notificación`,
                            variant: "success",
                            solid: true,
                            toaster: "b-toaster-bottom-center",
                        }
                    );
                    */
                    vm.loading = false;
                    vm.showDialog = true;
                    vm.$emit("success");
                }).catch( error => {
                    vm.loading = false;
                });
            })
                
        }
    },
    computed:{
        readyForUpload(){
            return this.file instanceof File ? true : false;
        }
    }
}
</script>