<template>
  <div>
    <v-app>
      <v-card>
        <v-card-title>
          Productos
          <v-spacer></v-spacer>
          <v-btn
            class
            @click="$emit('openDialogEditarProducto',selected[0])"
            v-show="selected.length"
            text
            icon
            color="green"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class
            @click="softDelete(selected[0])"
            v-show="selected.length"
            text
            icon
            color="red"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn class @click="$emit('open-carga-masiva-productos-dialog')" text icon color="blue">
            <v-icon style="color: blue !important;">icon-file_upload</v-icon>
          </v-btn>
          <v-btn class href="#/productos/crear" text icon color="blue">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar a Excel</span>
          </v-tooltip>
          <v-btn class @click="fetchData" text icon color="blue">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card-text >
              <div class="d-flex justify-space-between mb-4">
              <v-text-field
                  v-model="search"
                  clearable
                  label="Buscar"
                  hide-details
                  dense
                  solo
                  class="mr-3"
              ></v-text-field>
              <v-btn class="btn-starkoms-primary" style="min-width: 138px;" dark>Buscar</v-btn>
              </div>
          </v-card-text>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            :search="search"
            :single-select="true"
            show-select
            v-model="selected"
            no-results-text="No hay resultados disponibles"
            no-data-text="No hay datos disponibles"
            loading-text="Cargando datos..."
            class="elevation-1 datatable"
          >
            <template v-slot:item.is_bundle="{ item }">
              <td class="text-center">
                <button
                  v-if="item.is_bundle"
                  :class="'btn btn-md btn-warning'"
                  v-text="'Bundle'"
                ></button>
                <button
                  v-else
                  :class="'btn btn-md btn-danger'"
                  v-text="'Producto'"
                ></button>
              </td>
            </template>
            <template v-slot:item.active="{ item }">
              <td class="text-center">{{ item.active ? 'Si': 'No' }}</td>
            </template>
            <template v-slot:item.created_at="{ item }">
              <td class="text-center">{{ $moment(item.created_at).format('YYYY-MM-DD hh:mm:ss') }}</td>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount" :total-visible="10"></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-app>
    <dialog-editar-producto @success="fetchData"></dialog-editar-producto>
    <carga-masiva-productos></carga-masiva-productos>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogEditarProducto from "@/views/oms/Productos/DialogEditarProducto.vue";
import CargaMasivaProductos from '@/views/oms/Productos/CargaMasivaProductos.vue';
import EventBus from "@/event-bus";
export default {
  components: {
    DialogEditarProducto,
    CargaMasivaProductos
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      selected: [],
      headers: [
        { text: "Nombre producto", value: "name" },
        { text: "EAN", value: "ean" },
        { text: "SKU", value: "sku" },
        { text: "Precio", value: "price" },
        { text: "Producto/Bundle", value: "is_bundle" },
        { text: "Activo", value: "active" },
        { text: "Fecha creación", value: "created_at" },
      ],
      tableData: [],
      dateMsg:
        new Date().toISOString().substr(0, 10) +
        " " +
        new Date().toISOString().substr(11, 5),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard Productos", route: "productos" },
    ]);
    this.fetchData();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //get the store, created Product alert from crearProducto component
      if (localStorage.getItem("crearProducto") !== null) {
        this.createdProductMessage();
      }
      //get the store, created Bundle alert from crearProducto component
      if (localStorage.getItem("crearBundle") !== null) {
        this.createdBundleMessage();
      }
    },
    fetchData() {
      var vm = this;
      this.axios({
        url: "order/products",
        method: "POST",
        data: {
          store: this.$store.state.passport.user.default_store_id,
        },
      })
        .then((response) => {
          /**Pagination */
          vm.pageCount = Math.ceil(response.data.length / 10);
          vm.tableData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel(){
      var vm = this;
      this.axios({
        url: 'inventory/products/export/excel',
        method: 'POST',
        responseType: "blob",
      }).then( response => {
        const newBlob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        const data = window.URL.createObjectURL(newBlob);
        window.open(data, "_blank");
      }).catch( error => {
        console.log(error);
      });
    },
    openDialogEditar() {
      console.log("openDialogEditar");
      if (!this.selected[0]) return;
      console.log("kkkeke");
      EventBus.$emit("openDialogEditarProducto", this.selected[0]);
    },
    softDelete(item) {
      var vm = this;
      var confirmDelete = true;

      if (confirm("Está seguro que desea borrar el producto?")) {
        this.axios({
          url: "orders/products/product/isInBundles/" + item.id,
          method: "GET",
        })
        .then((response) => {
          if (response.data == true) {
            if (confirm("El producto está asociado a uno o más bundles. Si borra el producto también se borrarán los bundles (esta acción no puede deshacerse). ¿Confirma que desea continuar?")) {
              confirmDelete = true;
            }else{
              confirmDelete = false;
            }
          }
          if (confirmDelete) {
            this.axios({
              url: "orders/products/product/" + item.id,
              method: "DELETE",
            })
            .then((response) => {
              vm.abrirMensajeSuccess("Producto borrado correctamente");
            })
            .catch((error) => {
              console.log(error);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    createdProductMessage() {
      let vm = this;
      let crearProducto = JSON.parse(localStorage.getItem("crearProducto"));

      this.$bvToast.toast(
        `El Producto: ${crearProducto.name} ha sido creado, el ${vm.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
      localStorage.removeItem("crearProducto");
    },
    createdBundleMessage() {
      let vm = this;
      let crearBundle = JSON.parse(localStorage.getItem("crearBundle"));

      this.$bvToast.toast(
        `El Bundle: ${crearBundle.name} ha sido creado, el ${vm.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
      localStorage.removeItem("crearBundle");
    },
    abrirMensajeSuccess(mensaje) {
      this.$bvToast.toast(mensaje, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
      this.fetchData();
    },
  },
};
</script>
<style lang="scss">
.v-btn__content{
  .icon-file_upload::before{
    color: #2196F3 !important
  }
}
</style>