<template>

    <v-dialog v-model="showDialog" max-width="800px">
        <v-card>
            <v-card-title>
                Editar {{product_name}}
            </v-card-title>
            <v-card-text v-if="mode == 'product'">
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="product.name"
                            :rules="nameRules"
                            label="Nombre"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="product.sku"
                            :rules="nameRules"
                            label="SKU"
                            required
                        ></v-text-field>
                    </v-col>
                     <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.ext_sku"
                            label="SKU externo"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="product.ean"
                            label="EAN"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="product.price"
                            :rules="priceRules"
                            label="Precio"
                            required
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.high"
                            :rules="priceRules"
                            label="Altura"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.width"
                            :rules="priceRules"
                            label="Ancho"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.depth"
                            :rules="priceRules"
                            label="Profundidad"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.weight"
                            :rules="priceRules"
                            label="Peso"
                            required
                            suffix="kg"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" >
                        <v-select
                            v-model="product.category_id"
                            :items="categoriesList"
                            :rules="categoryRules"
                            label="Categoría"
                            required
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                    <v-btn color="primary" :disabled="loading" @click="sendFormProduct">Guardar cambios</v-btn>
                    </v-col>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-switch  v-model="product.active"  :label="'Activo'"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="mode == 'bundle'">
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="bundle.name"
                            :rules="nameRules"
                            label="Nombre"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.sku"
                            :rules="nameRules"
                            label="SKU"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="bundle.ext_sku"
                            label="SKU externo"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.ean"
                            label="EAN"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="bundle.price"
                            :rules="priceRules"
                            label="Precio"
                            required
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-select
                            v-model="selectProducts"
                            :items="productsList"
                            label="Productos del bundle"
                            multiple
                            chips
                            :return-object="true"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.high"
                            :rules="priceRules"
                            label="Altura"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.width"
                            :rules="priceRules"
                            label="Ancho"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.depth"
                            :rules="priceRules"
                            label="Profundidad"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.weight"
                            :rules="priceRules"
                            label="Peso"
                            required
                            suffix="kg"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" >
                        <v-select
                            v-model="bundle.category_id"
                            :items="categoriesList"
                            :rules="categoryRules"
                            label="Categoría"
                            required
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                    <v-btn color="primary" :disabled="loading" @click="sendFormBundle">Guardar cambios</v-btn>
                    </v-col>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-switch  v-model="bundle.active"  :label="'Activo'"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                
                <v-data-table
                    v-show="selectProducts.length && mode == 'bundle'"
                    :headers="headers"
                    :items="selectProducts"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    class="elevation-1 datatable"
                >
                    <template v-slot:item.quantity="{ item }">
                        <td class="text-center">
                            <v-text-field v-model="item.quantity" type="number" min="1">
                            </v-text-field>
                        </td>
                    </template>
                </v-data-table>
            
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
import EventBus from '@/event-bus';
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            mode: "product",
            product: {
                name: '',
                ean: '',
                sku: '',
                ext_sku: undefined,
                price: undefined,
                category_id: undefined,
                high: 0.0,
                width: 0.0,
                depth: 0.0,
                weight: 0.0,
                active: false,
            },
            bundle: {
                name: '',
                ean: '',
                sku: '',
                ext_sku: undefined,
                price: undefined,
                category_id: undefined,
                products: [],
                active: false,
                high: 0.0,
                width: 0.0,
                depth: 0.0,
                weight: 0.0,
            },
            nameRules: [
                v => !!v || 'El Campo es requerido',
                //v => v.length <= 10 || 'asdf',
            ],
            priceRules: [
                v => !!v || 'El Campo es requerido',
                v => v > 0 || 'Debes ingresar un valor mayor a 0'
            ],
            categoryRules: [
                (v) => !!v || "Debes elegir una opción"
            ],
            loading: false,
            productsList: [],
            categoriesList: [],
            selectProducts: [],
            headers: [
                {text: 'Producto', value: 'text'},
                {text: 'Cantidad', value: 'quantity'}
            ],
            product_id: undefined,
            product_name: undefined,
        }
    },
    created(){
        var vm = this;
        this.$parent.$on('openDialogEditarProducto', (product) => {
            console.log('AAAAAAAAAAAAAAA');
            this.product_id = product.id;
            this.product_name = product.name;
            this.productsList = [];
            this.axios({
                url: 'order/products_data_list',
                method: 'GET'
            }).then( response => {  
                vm.productsList = response.data.map(e => ({...e, quantity: 1}));
                vm.axios({
                    url: 'order/products/'+vm.product_id,
                    method: 'GET',
                }).then( response2 => {
                    vm.mode = response2.data.is_bundle ? 'bundle' : 'product';
                    if(response2.data.is_bundle){
                        vm.bundle = response2.data.product;
                        vm.selectProducts = [];
                        response2.data.bundle_products.forEach(elem => {
                            let producto = vm.productsList.find(item => item.value == elem.value);
                            if(producto){
                                producto.quantity = elem.quantity;
                                vm.selectProducts.push(producto);
                            }
                        });
                    }else{
                        vm.product = response2.data.product;
                    }
                    vm.showDialog = true;
                }).catch( error => {
                    console.log(error);
                });
            }).catch( error => {
                console.log(error);
            })
        });
    },
    methods: {
        fetchProducts()
        {
            this.productsList = [];
            var vm = this;
            this.axios({
                url: 'order/products_data_list',
                method: 'GET'
            }).then( response => {  
                
                vm.productsList = response.data.map(e => ({...e, quantity: 1}));
            }).catch( error => {
                console.log(error);
            })
        },
        fetchCategories(){
            this.categoriesList = [];
            var vm = this;
            this.axios({
                url: "inventory/categories_data_list",
                method: "GET",
            })
            .then((response) => {
                vm.categoriesList = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        sendFormProduct()
        {
            this.loading = true;
            var vm = this;
            this.axios({
                url: 'order/products/product/'+this.product_id,
                method: 'PUT',
                data: this.product
            }).then( response => {
                vm.loading = false;
                vm.$bvToast.toast('Producto actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.$emit('success');
                vm.showDialog = false;
            }).catch( error => {
                vm.loading = false;
                console.log(error);
            });
        },
        sendFormBundle()
        {
            this.loading = true;
            var vm = this;
            this.axios({
                url: 'order/products/bundle/'+this.product_id,
                method: 'PUT',
                data: {...this.bundle, products: this.selectProducts}
            }).then( response => {
                vm.loading = false;
                vm.$bvToast.toast('Bundle actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.$emit('success');
                vm.showDialog = false;
            }).catch( error => {
                vm.loading = false;
                console.log(error);
            });
        }
    },
    mounted()
    {
        this.fetchCategories();
    }
}
</script>