var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('v-card',[_c('v-card-title',[_vm._v(" Productos "),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],attrs:{"text":"","icon":"","color":"green"},on:{"click":function($event){return _vm.$emit('openDialogEditarProducto',_vm.selected[0])}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],attrs:{"text":"","icon":"","color":"red"},on:{"click":function($event){return _vm.softDelete(_vm.selected[0])}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":function($event){return _vm.$emit('open-carga-masiva-productos-dialog')}}},[_c('v-icon',{staticStyle:{"color":"blue !important"}},[_vm._v("icon-file_upload")])],1),_c('v-btn',{attrs:{"href":"#/productos/crear","text":"","icon":"","color":"blue"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green"},on:{"click":_vm.exportExcel}},on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar a Excel")])]),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-3",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""}},[_vm._v("Buscar")])],1)])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"single-select":true,"show-select":"","no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos..."},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.is_bundle",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.is_bundle)?_c('button',{class:'btn btn-md btn-warning',domProps:{"textContent":_vm._s('Bundle')}}):_c('button',{class:'btn btn-md btn-danger',domProps:{"textContent":_vm._s('Producto')}})])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.active ? 'Si': 'No'))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":10},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('dialog-editar-producto',{on:{"success":_vm.fetchData}}),_c('carga-masiva-productos')],1)}
var staticRenderFns = []

export { render, staticRenderFns }